import React from 'react'
import 'twin.macro'
import PropTypes from 'prop-types'

import Container from './Container'
import { ButtonLink, ButtonA } from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { H2 } from './Headings'

const FooterCTA = ({
  headline,
  paragraph,
  ctaUrl,
  ctaText,
  ctaIcon,
  external,
}) => {
  return (
    <section tw="py-12 md:(py-8)" aria-label="Get Your Free Estimate">
      <Container>
        {/* Footer CTA */}
        <div tw="text-center content-center pt-0 md:(pt-10)">
          <H2>{headline}</H2>
          <p tw="text-base leading-relaxed text-gray-700 lg:(px-10 mx-10) xl:(px-24 mx-24) mb-8">
            {paragraph}
          </p>

          {ctaText ? (
            <>
              {external ? (
                <ButtonA
                  tw="block sm:(inline-block)"
                  href={ctaUrl}
                  target="_blank"
                  large
                >
                  <span tw="mr-3">{ctaText}</span>
                  <FontAwesomeIcon
                    icon={ctaIcon || faChevronRight}
                    tw="hidden sm:(inline-block)"
                  />
                </ButtonA>
              ) : (
                <ButtonLink
                  to={ctaUrl || '/residential/quote/'}
                  large
                  tw="block sm:(inline-block)"
                >
                  <span tw="mr-3">{ctaText}</span>
                  <FontAwesomeIcon
                    icon={ctaIcon || faChevronRight}
                    tw="hidden sm:(inline-block)"
                  />
                </ButtonLink>
              )}
            </>
          ) : null}
        </div>
      </Container>
    </section>
  )
}

FooterCTA.defaultProps = {
  external: false,
}

FooterCTA.propTypes = {
  external: PropTypes.bool,
}

export default FooterCTA
